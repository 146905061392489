import { css } from '@emotion/react';

import { colors } from '../../../styles/theme';
import { ButtonColorType } from '../props';

const getButtonColorProps = (color?: ButtonColorType): any => {
  switch (color) {
    case 'danger': {
      return css`
        background: ${colors.danger[500]};
        border-color: ${colors.danger[500]};
        color: ${colors.danger[800]};

        &:hover {
          background: ${colors.danger[600]};
          border-color: ${colors.danger[600]};
        }
      `;
    }

    case 'warning': {
      return css`
        background: ${colors.warning[500]};
        border-color: ${colors.warning[500]};
        color: ${colors.warning[800]};

        &:hover {
          background: ${colors.warning[600]};
          border-color: ${colors.warning[600]};
        }
      `;
    }

    case 'light': {
      return css`
        background: ${colors.light[500]};
        border-color: ${colors.light[500]};
        color: ${colors.light[800]};

        &:hover {
          background: ${colors.light[600]};
          border-color: ${colors.light[600]};
        }
      `;
    }

    case 'neutral': {
      return css`
        background: ${colors.neutral[200]};
        border-color: ${colors.neutral[200]};
        color: ${colors.neutral[800]};

        &:hover {
          background: ${colors.neutral[300]};
          border-color: ${colors.neutral[300]};
        }
      `;
    }

    case 'secondary': {
      return css`
        background: ${colors.secondary[500]};
        border-color: ${colors.secondary[500]};
        color: ${colors.secondary[800]};

        &:hover {
          background: ${colors.secondary[600]};
          border-color: ${colors.secondary[600]};
        }
      `;
    }

    case 'primary':
      return css`
        background: ${colors.primary[500]};
        border-color: ${colors.primary[500]};
        color: ${colors.primary[800]};

        &:hover {
          background: ${colors.primary[600]};
          border-color: ${colors.primary[600]};
        }
      `;

    case 'success': {
      return css`
        background: ${colors.success[500]};
        border-color: ${colors.success[500]};
        color: ${colors.success[800]};

        &:hover {
          background: ${colors.success[600]};
          border-color: ${colors.success[600]};
        }
      `;
    }

    case 'tertiary': {
      return css`
        background: ${colors.tertiary[500]};
        border-color: ${colors.tertiary[500]};
        color: ${colors.tertiary[800]};

        &:hover {
          background: ${colors.tertiary[600]};
          border-color: ${colors.tertiary[600]};
        }
      `;
    }

    default: {
      return css`
        background: ${colors.primary[500]};
        border-color: ${colors.primary[500]};
        color: ${colors.light[50]};

        &:hover {
          background: ${colors.primary[700]};
          border-color: ${colors.primary[700]};
        }
      `;
    }
  }
};

export default getButtonColorProps;
